/* eslint-disable */

import { defineStore } from "pinia";
import CampaignService from "@/services/CampaignService";
import LibraryTalkService from "@/services/LibraryTalkService";
import moment from "moment";
import { toast } from "vue3-toastify";
import TeamService from "@/services/TeamService";

export const useCampaignStore = defineStore("CampaignStore", {
  state: () => ({
    messageGoals: [
      {
        id: 1,
        name: "Create Talk With Text Editor",
        type: "text_editor",
        icon: "bi bi-pencil theOptionIcon",
        clicked: false,
      },

      {
        id: 2,
        name: "Upload PDF",
        type: "pdf",
        icon: "bi bi-file-earmark-pdf theOptionIcon",
        clicked: false,
      },

      {
        id: 3,
        name: "Video",
        type: "video",
        icon: "bi bi-play theOptionIcon",
        clicked: false,
      },
    ],

    wizardSteps: [
      {
        id: 1,
        step: "message_goal_step",
        allRequiredInputFieldsFilled: false,
      },
      {
        id: 2,
        step: "quiz_selection_step",
        allRequiredInputFieldsFilled: false,
      },
      {
        id: 3,
        step: "text_editor_step", 
        allRequiredInputFieldsFilled: false,
      },
      {
        id: 4,
        step: "pdf_step",
        allRequiredInputFieldsFilled: false,
      },

      {
        id: 5,
        step: "video_step",
        allRequiredInputFieldsFilled: false,
      },
      {
        id: 6,
        step: "quiz_builder_step",
        allRequiredInputFieldsFilled: false,
      },
      {
        id: 7,
        step: "message_details_step",
        allRequiredInputFieldsFilled: false,
      },
      {
        id: 8,
        step: "save_or_publish_step",
        allRequiredInputFieldsFilled: false,
      },
    ],

    willItHaveQuiz: [
      {
        id: 1,
        answer: "YES",
      },

      {
        id: 2,
        answer: "NO",
      },
    ],

    post: {
      postData: {
        title: "",
        content: "",
        has_quiz: "",
        message_goal_id: 1, //set text as default
        pdf_url_path: "",
        video_embed_code: "",
      },
    },

    message: {
      messageData: {
        name: "",
        messageContent: "",
        send_date: "",
        send_time: "",
        status: "",
        statusId: "", //used show selected option
      },
    },

    updateMessage: {
      name: "",
      message: "",
      send_date: "",
      send_time: "",
      random_string: "",
    },

    statusArray: [
      {
        id: 1,
        label: "SAVE AS DRAFT",
        value: 0,
      },
      {
        id: 2,
        label: "PUBLISH LIVE",
        value: 1,
      },
    ],

    currentStep: "message_goal_step",
    currentIndex: 0,

    progressPercentage: 10,

    showCreateCampaignModal: false,
    showDeleteCampaignModal: false,
    showEditCampaignModal: false,

    allCampaigns: [],
    timezones: [],
    teams: [],

    newCampaignFormData: {
      name: "",
      team_id: "",
      from_name: "",
      timezone_id: "",
    },

    currentCampaign: {},
    currentTeam: {},
    scheduledMessages: [],

    allTextTalks: [],
    showTextTalksLibraryModal: false,
    showPreviewTalkFromLibarayModal: false,

    currentSafetyTalkTemplate: {},

    isLoading: false,

    showAIpromptModal: false,
    showAIresultsModal: false,

    languages: [
      { id: 1, type: "English" },
      { id: 2, type: "Spanish" },
    ],

    promptContent: {
      language: "English", //default
      prompt: "",
    },

    theAIGenerateTalk: {},

    showQuizDetailsModal: false,
    showAddQuestionModal: false,
    showDeleteQuestionModal: false,

    quiz: {
      quizData: {
        name: "",
        min_pass_score: 100,
      },
    },

    passScores: [
      {
        id: 1,
        score: 100,
      },
      {
        id: 2,
        score: 90,
      },
      {
        id: 3,
        score: 80,
      },

      {
        id: 4,
        score: 70,
      },

      {
        id: 5,
        score: 60,
      },

      {
        id: 6,
        score: 50,
      },
    ],

    questions: {
      questionsData: [],
    },

    newQuestionObject: {
      question: "",
      type: "",
      correctAnswerIndex: "",

      answersData: [
        {
          answer: "",
        },
      ],
    },

    currentQuestionIndex: null,

    clonedQuestionObject: {}, //formely clonedObject

    pdf: "",
    pdf_name: "",
    source1: "",
    showDeletePDFmodal: false,
    showPDFlibraryModal: false,
    allPDFSafetyTalks: [],

    renderVideoEmbedCode: "",
    pastedEmbedVideoCode: "",
    showEmbedVideoModal: false,
    allVideoSafetyTalks: [],

    showVideoLibraryModal: false,

    motherArray: [],

    postDetails: {},
    doesPDFNameExistInLibrary: "",

    updated_pdf_title: "",
    updated_pdf_name: "",
    updated_pdf: "",
    old_pdf_name: "",

    videoPost: {
      title: "",
      video_embed_code: "",
    },

    showEditMessageModal: false,
    currentMessage: {},

    showDeleteScheduledMessageModal: false,

    statusValue: "",

    quizDetails: {},

    allAcknowledgments: [],
    scheduledMessage: {},

    employees: [],

    employee: {},
    team: {},
    acknowledgements: [],

    showPreviewTalkForAEmployee: false,

    currentPost: {},

    thisVideoIsYoutube: false,

    showGenQuizWithAIModal: false,
    showAIQuizResultsModal: false,

    quizTextCreatorFormData: {
      talk_content: "",
      amt_of_questions: 3,
      amt_of_answers_per_question: 4,
      language: "English",
    },

    quizPDFCreatorFormData: {
      pdf_content: "",
      amt_of_questions: 3,
      amt_of_answers_per_question: 4,
      language: "English",
    },

    questionsCreatedByAIArray: [],

    showEditQuestiosModal: false,

    showResendTalkEmailToEmployeeModal: false,

    currentEmployee: {},
    currentAck: {},

    showViewUserTeamsModal: false,

    currentUserTeams: [],

    showMassResendModal: false,

    showCampaignExportOptionsModal: false,
    isExporting: false,

    showQuizAttemptModal: false,
    currentQuizAttemptEmployee: {},
    quizAttempts: [],

    isLoadingQuizAttempts: false,


  }),

  getters: {},

  actions: {
    fetchAllMyCampaigns() {
      CampaignService.getAllCampaigns() 
        .then((response) => {
          this.allCampaigns = response.data.campaigns;
          this.timezones = response.data.timezones;
          this.teams = response.data.teams;

          this.loading = false;

          // // eslint-disable-next-line no-console
          // console.log('fetchAllMyCampaigns', response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

    //For ViewACampaign page
    fetchCampaignDetails(random_string) {
      CampaignService.showCampaign(random_string)
        .then((response) => {
          this.currentCampaign = response.data.campaign;
          this.currentTeam = response.data.campaign.team;
          this.scheduledMessages = response.data.scheduledMessages;

          // console.log(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

    //CreateAMessage page
    fetchCampaignDetailsOnly(random_string) {
      CampaignService.showCampaign(random_string)
        .then((response) => {
          this.currentCampaign = response.data.campaign;

          // console.log('fetchCampaignDetailsOnly', this.currentCampaign)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    storeNewCampaign() {
      CampaignService.storeCampaign(this.newCampaignFormData) 
        .then(() => {
          //hydrate list
          this.fetchAllMyCampaigns();

          //reset the form fields
          this.newCampaignFormData = {
            name: "",
            team_id: "",
            timezone_id: "",
            from_name: "",
          };

          this.closeCreateCampaignModal();

          //   // eslint-disable-next-line no-console
          //   console.log(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

    updateCampaignDetails() {
      CampaignService.updateCampaignDetails(
        this.currentCampaign.random_string,
        this.currentCampaign
      )
        .then(() => {
          this.closeEditCampaignModal();

          // // eslint-disable-next-line no-console
          // console.log(response.data)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    deleteCampaign() {
      CampaignService.deleteThisCampaign(this.currentCampaign.random_string)
        .then(() => {
          const campaignIndexToDelete = this.allCampaigns.findIndex(
            (camp) => camp.id === this.currentCampaign.id
          );

          if (campaignIndexToDelete !== -1) {
            this.allCampaigns.splice(campaignIndexToDelete, 1);
          }

          this.closeDeleteCampaignModal();
        })
        .catch((error) => {
          console.error("Error deleting campaign:", error);
        });
    },

    //this only fetches the text-based safety talks from library
    fetchAllTextSafetyTalks() {
      this.isLoading = true;

      LibraryTalkService.getTextTalksOnly()
        .then((response) => {
          this.isLoading = false;

          this.allTextTalks = response.data;

          // console.log(response.data);
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    limitText(body) {
      if (body.length > 40) {
        return body.substring(0, 40) + "...";
      }

      return body;
    },

    limitTextForCampaignTitle() {
      if (!this.currentCampaign || !this.currentCampaign.name) {
        return ""; // Return an empty string or some default value if the campaign or its name is undefined
      }

      let campTitle = this.currentCampaign.name;

      if (campTitle.length > 40) {
        return campTitle.substring(0, 40) + "...";
      }

      return campTitle;
    },

    limitTextForCampaignHeader(body) {
      console.log(body);
      if (body.length > 40) {
        return body.substring(0, 40) + "...";
      }

      return body;
    },

    limitTextTalkBody(body) {
      if (body.length > 50) {
        return body.substring(0, 50) + "...";
      }

      return body;
    },

    handleImageUploadToServer(event) {
      return new Promise((resolve, reject) => {
        const file = event.target.files[0];

        if (file.size > 1024 * 1024) {
          // 1MB in bytes
          alert("File size should not exceed 1MB");
          reject("File size too large");
          return;
        }

        const formData = new FormData();
        formData.append("image", file);

        CampaignService.uploadTextEditorImage(formData)
          .then((response) => {
            resolve(response.data); // Resolve the promise with the image URL
          })
          .catch((error) => {
            console.error(error);
            reject(error); // Reject the promise on error
          });
      });
    },

    previousStep() {
      this.currentIndex--;
      this.progressPercentage = this.progressPercentage - 10;
    },

    incrementProgressPercentage() {
      this.progressPercentage = this.progressPercentage + 10;
    },

    decrementProgressPercentage() {
      this.progressPercentage = this.progressPercentage - 10;
    },

    setSelectedMessageGoal(messageGoal) {
      this.post.postData.message_goal_id = messageGoal.id;
    },

    setWillHaveQuizAnswer(willIt) {
      this.post.postData.has_quiz = willIt.answer;
    },

    goToQuizSelectionStep() {
      //do message check before
      if (this.messageGoalCheck()) {
        this.currentStep = "quiz_selection_step";
        this.incrementProgressPercentage();
      }
    },

    navigateToTalkCreationBasedOnMessageGoal() {
      if (this.hasQuizCheck()) {
        if (this.post.postData.message_goal_id === 1) {
          this.goToTextEditorStep();
        }

        if (this.post.postData.message_goal_id === 2) {
          this.goToPDFStep();
        }

        if (this.post.postData.message_goal_id === 3) {
          this.goToVideoStep();
        }
      }
    },

    goToTextEditorStep() {
      this.currentStep = "text_editor_step";
      this.incrementProgressPercentage();
    },

    goToPDFStep() {
      this.currentStep = "pdf_step";
      this.incrementProgressPercentage();
    },

    goToVideoStep() {
      this.currentStep = "video_step";
      this.incrementProgressPercentage();
    },

    gotoQuizOrNotAfterTextEditor() {
      if (this.textEditorValidationCheck()) {
        if (this.post.postData.has_quiz === "YES") {
          //give the quiz a name
          this.quiz.quizData.name = this.post.postData.title + " Quiz";

          this.goToQuizBuilderStep();
        } else {
          this.skipQuizBuilderStep();
        }
      }
    },

    goToQuizBuilderStep() {
      this.currentStep = "quiz_builder_step";
      this.incrementProgressPercentage();
    },

    skipQuizBuilderStep() {
      this.currentStep = "message_details_step";
      this.incrementProgressPercentage();
    },

    gotoQuizOrNotAfterPDFStep() {
      if (this.pdfCheck()) {
        if (this.post.postData.has_quiz === "YES") {
          //give the quiz a name
          this.quiz.quizData.name = this.post.postData.title + " Quiz";

          this.goToQuizBuilderStep();
        } else {
          this.skipQuizBuilderStep();
        }
      }
    },

    gotoQuizOrNotAfterVideoStep() {
      if (this.videoCheck()) {
        if (this.post.postData.has_quiz === "YES") {
          //give the quiz a name
          this.quiz.quizData.name = this.post.postData.title + " Quiz";

          this.goToQuizBuilderStep();
        } else {
          this.skipQuizBuilderStep();
        }
      }
    },

    gotMessageDetailsStep() {
      if (this.post.postData.has_quiz === "NO") {
        this.currentStep = "message_details_step";
        this.incrementProgressPercentage();
      }

      if (this.post.postData.has_quiz === "YES") {
        if (this.quizBuilderCheck()) {
          this.currentStep = "message_details_step";
          this.incrementProgressPercentage();
        }
      }
    },

    goToSaveOrPublishStep() {
      if (this.messageValidationCheck()) {
        this.currentStep = "save_or_publish_step";
        this.incrementProgressPercentage();
      }
    },

    wizardFinished() {
      if (this.statusCheck()) {
        this.onComplete();

        this.progressPercentage = 100;
      }
    },

    setMessageStatus(status) {
      this.message.messageData.status = status.value;
      this.message.messageData.statusId = status.id;
    },

    goBackToMessageGoal() {
      this.currentStep = "message_goal_step";
      this.decrementProgressPercentage();
    },

    goBackToQuizSelection() {
      this.currentStep = "quiz_selection_step";
      this.decrementProgressPercentage();
    },

    goBackToTalkCreationBasedOnMessageGoal() {
      if (this.post.postData.message_goal_id === 1) {
        this.currentStep = "text_editor_step";
        this.decrementProgressPercentage();
      }

      if (this.post.postData.message_goal_id === 2) {
        this.currentStep = "pdf_step";
        this.decrementProgressPercentage();
      }

      if (this.post.postData.message_goal_id === 3) {
        this.currentStep = "video_step";
        this.decrementProgressPercentage();
      }
    },

    goingBackFromMessagDetails() {
      if (this.post.postData.has_quiz === "YES") {
        this.currentStep = "quiz_builder_step";

        this.decrementProgressPercentage();
      } else {
        this.goBackToTalkCreationBasedOnMessageGoal();
      }
    },

    goingBackFromSaveAsDraftOrPublish() {
      this.currentStep = "message_details_step";
      this.decrementProgressPercentage();
    },

    openCreateCampaignModal() {
      this.showCreateCampaignModal = true;
    },

    closeCreateCampaignModal() {
      this.showCreateCampaignModal = false;
    },

    openDeleteCampaignModal() {
      this.showDeleteCampaignModal = true;
    },

    closeDeleteCampaignModal() {
      this.showDeleteCampaignModal = false;
    },

    openEditCampaignModal() {
      this.showEditCampaignModal = true;
    },

    closeEditCampaignModal() {
      this.showEditCampaignModal = false;
    },

    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    formatTime(time) {
      var formatedTime = moment(time, "HH:mm").format("h:mma");

      return formatedTime;
    },

    setCurrentCampaign(campaign) {
      this.currentCampaign = campaign;
    },

    openTextTalksLibraryModal() {
      this.showTextTalksLibraryModal = true;
    },

    closeTextTalksLibraryModal() {
      this.showTextTalksLibraryModal = false;
    },

    openPreviewTalkLibraryModal() {
      this.showPreviewTalkFromLibraryModal = true;
    },

    closePreviewTalkLibraryModal() {
      this.showPreviewTalkFromLibraryModal = false;
    },

    setCurrentSafetyTalkTemplate(talk) {
      this.currentSafetyTalkTemplate = talk;

      console.log(this.currentSafetyTalkTemplate);
    },

    openAIpromptModal() {
      this.showAIpromptModal = true;
    },

    closeAIpromptModal() {
      this.showAIpromptModal = false;
    },

    openAIresultsModal() {
      this.showAIresultsModal = true;
    },

    closeAIresultsModal() {
      this.showAIresultsModal = false;
    },

    openQuizDetailsModal() {
      this.showQuizDetailsModal = true;
    },

    closeQuizDetailsModal() {
      this.showQuizDetailsModal = false;
    },

    openAddQuestionModal() {
      this.showAddQuestionModal = true;
    },

    closeAddQuestionModal() {
      this.showAddQuestionModal = false;
    },

    openDeleteQuestionModal() {
      this.showDeleteQuestionModal = true;
    },

    closeDeleteQuestionModal() {
      this.showDeleteQuestionModal = false;
    },

    generateAIcontent() {
      //check if user entered prompt
      if (this.promptContent.prompt == "") {
        alert(
          "Please describe what kind of safety talk should be should be created"
        );

        return;
      }

      const formData = {
        promptContent: this.promptContent,
      };

      this.isLoading = true;

      CampaignService.generateTextTalkWithAI(formData)
        .then((response) => {
          // console.log(response.data);

          this.theAIGenerateTalk = response.data.contentArray.talk;

          this.openAIresultsModal();

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setCorrectAnswerIndex(index) {
      this.newQuestionObject.correctAnswerIndex = index;
    },

    addNewAnswer() {
      this.newQuestionObject.answersData.push({
        answer: "",
      });
    },

    deleteAnswer(index) {
      this.newQuestionObject.answersData.splice(index, 1);
    },

    setCurrentQuestionIndex(index) {
      this.currentQuestionIndex = index;
    },

    deleteQuestionFromArray() {
      this.questions.questionsData.splice(this.currentQuestionIndex, 1);

      this.closeDeleteQuestionModal();
    },

    addToQuestionsDataArray() {
      if (this.newQuestionObject.answersData.length === 1 || this.newQuestionObject.correctAnswerIndex === "") {

        alert("A question must have at least 2 answers and one correct answer selected.");

      } else {
        this.clonedQuestionObject = this.newQuestionObject;

        this.questions.questionsData.push(this.clonedQuestionObject);

        this.closeAddQuestionModal();

        this.newQuestionObject = {
          question: "",
          type: "",
          correctAnswerIndex: "",

          answersData: [
            {
              answer: "",
            },
          ],
        };
      }
    },

    pdfOnChange(e) {
      let file = e.target.files[0];

      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        alert("File size should not exceed 2MB");

        // Reset the file input
        e.target.value = ""; // Clear the input value

        return;
      }

      //Proceed
      this.pdf = file;

      //genereate a uniqe name
      const randomString = Math.random().toString(36).substring(2, 7);
      this.pdf_name = randomString + file.name;

      // // eslint-disable-next-line no-console
      // console.log(this.pdf, this.pdf_name);
    },

    onChangePDFupdate(e) {
      this.updated_pdf = e.target.files[0];

      //genereate a uniqe name
      const randomString = Math.random().toString(36).substring(2, 7);
      this.updated_pdf_name = randomString + e.target.files[0].name;

      this.postDetails.pdf_url_path = randomString + e.target.files[0].name;

      // // eslint-disable-next-line no-console
      // console.log(this.updated_pdf, this.updated_pdf_name);
    },

    savePDF() {
      this.isLoading = true;

      const formData = new FormData();

      formData.set("pdf", this.pdf);
      formData.set("pdf_name", this.pdf_name);

      //assign the pdf_name to pdf_url_path to store in DB
      this.post.postData.pdf_url_path = this.pdf_name;

      CampaignService.uploadThisPdf(formData)
        .then((response) => {
          this.isLoading = false;

          this.source1 = response.data;

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    saveUpdatedPDF() {
      this.loading = true;

      const formData = new FormData();
      formData.set("updated_pdf_title", this.updated_pdf_title);
      formData.set("updated_pdf_name", this.updated_pdf_name);
      formData.set("updated_pdf", this.updated_pdf);
      formData.set("old_pdf_name", this.old_pdf_name);
      formData.set("post_id", this.postDetails.id);

      CampaignService.updatePDF(formData)
        .then((response) => {
          this.loading = false;

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    deletePDF() {
      const formData = new FormData();

      formData.set("pdf", this.pdf);
      formData.set("pdf_name", this.pdf_name);

      CampaignService.deleteFileFromDOSpaces(formData)
        .then((response) => {
          this.source1 = "";

          this.pdf_name = "";

          this.closeDeletePDFmodal();

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    openDeletePDFmodal() {
      this.showDeletePDFmodal = true;
    },

    closeDeletePDFmodal() {
      this.showDeletePDFmodal = false;
    },

    openPDFLibraryModal() {
      this.showPDFlibraryModal = true;
    },

    closePDFLibraryModal() {
      this.showPDFlibraryModal = false;
    },

    fetchPDFtalksFromLibrary() {
      this.isLoading = true;

      LibraryTalkService.getAllPDFtalks()
        .then((response) => {
          this.allPDFSafetyTalks = response.data.pdfTalks;

          this.isLoading = false;

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    setSelectedPDFfromLibrary() {
      this.source1 = this.currentSafetyTalkTemplate.pdf_url_path;
      this.post.postData.title = this.currentSafetyTalkTemplate.title;

      this.post.postData.pdf_url_path =
        this.currentSafetyTalkTemplate.file_name;

      this.isLoading = true;

      //deleay to give the pdf some time to load in view
      setTimeout(() => {
        this.closePreviewTalkLibraryModal();
        this.closePDFLibraryModal();
        this.isLoading = false;
      }, 1500);
    },

    openVideoEmbedModal() {
      this.showEmbedVideoModal = true;
    },

    closeVideoEmbedModal() {
      this.showEmbedVideoModal = false;
    },

    setPastedCodeToRender() {
      var pastedString = this.pastedEmbedVideoCode;

      const checkUrls =
        pastedString.includes("www.youtube.com") ||
        pastedString.includes("player.vimeo.com") ||
        pastedString.includes("media.publit.io") ||
        pastedString.includes("wistia");

      //make sure embed code is from approved domains only
      // Youtube, Vimeo, Pubilio
      //If checkUrls returns true
      if (checkUrls) {
        if (pastedString.includes("www.youtube.com")) {
          //replace width to 100%
          var start = "iframe";
          var ending = "src";
          var string = pastedString;
          var replacement = 'width="100%" height="100%"';
          var newIframeCode = this.myReplace(
            string,
            replacement,
            start,
            ending
          );

          this.renderVideoEmbedCode = newIframeCode;

          this.post.postData.video_embed_code = this.renderVideoEmbedCode;

          //NOTE: this is  only for when edit post content video
          this.postDetails.video_embed_code = this.renderVideoEmbedCode;

          this.closeVideoEmbedModal();
        } else {
          //other video plaforms give the embed already ressponsive, so no need to modify it
          this.renderVideoEmbedCode = this.pastedEmbedVideoCode;

          this.post.postData.video_embed_code = this.renderVideoEmbedCode;

          //NOTE: this is  only for when edit post content video
          this.postDetails.video_embed_code = this.renderVideoEmbedCode;

          this.closeVideoEmbedModal();
        }
      } else {
        alert("Must embed code from approved domains");
      }
    },

    //this removes the width and hieght and replace it with mine
    myReplace(string, replacement, start, ending) {
      replacement = start + " " + replacement;

      // eslint-disable-next-line no-useless-escape
      var pattern = new RegExp(start + " .+(?=" + ending + ")", "g");

      return string.replace(pattern, replacement + " ");
    },

    populateTheSelectedVideo() {
      this.isLoading = true;

      this.renderVideoEmbedCode =
        this.currentSafetyTalkTemplate.video_embed_code;

      this.post.postData.video_embed_code = this.renderVideoEmbedCode;
      this.post.postData.title = this.currentSafetyTalkTemplate.title;

      //this is just to satisfy the 'pastedEmbedVideoCode' check in post validation.
      this.pastedEmbedVideoCode =
        this.currentSafetyTalkTemplate.video_embed_code;

      this.isLoading = false;

      this.closePreviewTalkLibraryModal();
      this.closeVideoLibraryModal();
    },

    fetchAllVideoSafetyTalks() {
      this.isLoading = true;

      CampaignService.getAllVideoTalksFromLibrary()
        .then((response) => {
          this.allVideoSafetyTalks = response.data;

          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    openVideoLibraryModal() {
      this.showVideoLibraryModal = true;
    },

    closeVideoLibraryModal() {
      this.showVideoLibraryModal = false;
    },

    messageGoalCheck() {
      if (this.post.postData.message_goal_id === "") {
        // const message = "Please select a goal";

        alert("Please select a goal");

        return false;
      } else {
        return true;
      }
    },

    hasQuizCheck() {
      if (this.post.postData.has_quiz === "") {
        alert("Please select a quiz option");

        return false;
      } else {
        return true;
      }
    },

    textEditorValidationCheck() {
      if (this.post.postData.title === "") {
        alert("Safety talk title is required");

        return;
      }

      if (this.post.postData.content === "") {
        alert("You have not added any content to the text editor");

        return;
      }

      return true;
    },

    quizBuilderCheck() {
      if (this.quiz.quizData.name === "") {
        alert("Give the quiz a name it's required.");

        return;
      }

      if (this.questions.questionsData.length === 0) {
        alert("A quiz must have at least one question with 2 answers.");

        return;
      }

      return true;
    },

    messageValidationCheck() {
      if (this.message.messageData.name === "") {
        alert("Message name is required");

        return;
      }

      if (this.message.messageData.messageContent === "") {
        alert("Message content is required");

        return;
      }

      if (this.message.messageData.send_date === "") {
        alert("A send date is required");

        return;
      }

      if (this.message.messageData.send_time === "") {
        alert("Send time is required");

        return;
      }

      return true;
    },

    statusCheck() {
      if (this.message.messageData.status === "") {
        alert(`Please select "Publish Live" or "Save As Draft"`);

        return;
      }

      return true;
    },

    pdfCheck() {
      if (this.post.postData.title === "") {
        alert("Safety talk title is required");

        return;
      }

      if (this.source1 === "") {
        alert("Please upload the PDF file");

        return;
      }

      return true;
    },

    videoCheck() {
      if (this.post.postData.title === "") {
        alert("Safety talk title is required");

        return;
      }

      if (this.post.postData.video_embed_code === "") {
        alert("A video embed is required.");

        return;
      }

      return true;
    },

    onComplete() {
      this.isLoading = true;

      this.motherArray.push(this.post, this.message, this.quiz, this.questions);

      CampaignService.storeNewMessage(
        this.currentCampaign.random_string,
        this.motherArray
      )
        .then((response) => {
          if (response.status == 200) {
            setTimeout(() => {
              //redirect to view campaign page
              this.router.push({
                name: "ViewACampaign",
                params: { random_string: this.currentCampaign.random_string },
              });

              this.resetWizard();
              this.isLoading = false;
            }, 1000);
          }

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    resetWizard() {
      this.currentStep = "message_goal_step";
      this.progressPercentage = 10;

      this.post.postData.message_goal_id = 1;
      this.post.postData.has_quiz = "";
      this.post.postData.title = "";
      this.post.postData.content = "";
      this.post.postData.pdf_url_path = "";
      this.post.postData.video_embed_code = "";
      this.pdf = "";
      this.pdf_name = "";

      this.questions.questionsData = [];
      this.quiz.quizData.name = "";
      this.quiz.quizData.min_pass_score = 100;

      this.newQuestionObject.question = ""
      this.newQuestionObject.type = ""
      this.newQuestionObject.correctAnswerIndex = ""


      this.message.messageData.name = "";
      this.message.messageData.messageContent = "";
      this.message.messageData.send_date = "";
      this.message.messageData.send_time = "";
      this.message.messageData.status = "";
      this.message.messageData.statusId = "";

      this.renderVideoEmbedCode = "";
      this.pastedEmbedVideoCode = "";

      this.quizTextCreatorFormData.language = "English";
      this.quizTextCreatorFormData.amt_of_questions = 3;
      this.quizTextCreatorFormData.amt_of_answers_per_question = 4;
      this.quizTextCreatorFormData.talk_content = "";

      this.quizPDFCreatorFormData.language = "English";
      this.quizPDFCreatorFormData.amt_of_questions = 3;
      this.quizPDFCreatorFormData.amt_of_answers_per_question = 4;
      this.quizPDFCreatorFormData.pdf_content = "";
    },

    buildPDFurl2() {
      let url = "";

      if (this.doesPDFNameExistInLibrary === 1) {
        url =
          "https://safelyio.nyc3.cdn.digitaloceanspaces.com/pdftalks/" +
          this.postDetails.pdf_url_path;

        this.source1 = url;

        return;
      }

      if (this.doesPDFNameExistInLibrary === 0) {

        // url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.postDetails.manager_id + "/9j1ks/" + this.postDetails.pdf_url_path;

        let url = null;

        //if master_account_admin_id is null it means it was created by the master manager account
        if(this.postDetails.master_account_admin_id == null){

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.postDetails.manager_id + "/9j1ks/" + this.postDetails.pdf_url_path;

        }

        //this is mean it was created by a master account admin
        if(this.postDetails.master_account_admin_id !== null){

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.postDetails.master_account_admin_id + "/9j1ks/" + this.postDetails.pdf_url_path;

        }

        this.source1 = url;

        return;

      }

      // return url;
    },

    savePostData() {
      // console.log(this.postDetails)

      CampaignService.updatePost(
        this.postDetails.random_string,
        this.postDetails
      )
        .then((response) => {
          toast("Safety talk changes saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    updateVideoPost() {
      CampaignService.updateVideoPost(
        this.postDetails.random_string,
        this.postDetails
      )
        .then((response) => {
          toast("Safety talk changes saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });
          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    openShowEditMessageModal() {
      this.showEditMessageModal = true;
    },

    closeShowEditMessageModal() {
      this.showEditMessageModal = false;
    },

    setCurrentMessage(scheduledMessage) {
      //for the form
      this.updateMessage.random_string = scheduledMessage.random_string;
      this.updateMessage.name = scheduledMessage.name;
      this.updateMessage.message = scheduledMessage.message;
      this.updateMessage.send_date = scheduledMessage.send_date;
      this.updateMessage.send_time = scheduledMessage.send_time;

      //to update view without refresh AND for when deleting a message
      this.currentMessage = scheduledMessage;
    },

    saveMessageUpdate() {  

      // console.log(this.updateMessage) 

      CampaignService.updateScheduledMessage(this.updateMessage.random_string, this.updateMessage
      )
        .then((response) => {
          this.currentMessage.name = this.updateMessage.name;
          this.currentMessage.message = this.updateMessage.message;
          this.currentMessage.send_date = this.updateMessage.send_date;
          this.currentMessage.send_time = this.updateMessage.send_time;

          this.closeShowEditMessageModal();
          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });

    },

    openDeleteScheduledMessageModal() {
      this.showDeleteScheduledMessageModal = true;
    },

    closeDeleteScheduledMessageModal() {
      this.showDeleteScheduledMessageModal = false;
    },

    yesDeleteScheduledMessage() {
      CampaignService.deleteSceduledMessage(this.currentMessage.id)
        .then((response) => {
          this.closeDeleteScheduledMessageModal();

          const scheduledMessageToDelete = this.scheduledMessages.findIndex(
            (mess) => mess.id === this.currentMessage.id
          );

          if (scheduledMessageToDelete !== -1) {
            this.scheduledMessages.splice(scheduledMessageToDelete, 1);
          }

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    updateStatus(scheduledMessage) {
      //if the status id true/1 switch to false
      if (scheduledMessage.status) {
        scheduledMessage.status = 0;
        this.statusValue = scheduledMessage.status;

        //update the DB
        CampaignService.updateStatus(
          scheduledMessage.random_string,
          this.statusValue
        )
          .then((response) => {
            toast("Status updated!", {
              position: "bottom-right",
              autoClose: 1000,
            });

            // // eslint-disable-next-line no-console
            // console.log(response.data);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      } else {
        scheduledMessage.status = 1;
        this.statusValue = scheduledMessage.status;

        //update the DB
        CampaignService.updateStatus(
          scheduledMessage.random_string,
          this.statusValue
        )
          .then((response) => {
            toast("Status updated!", {
              position: "bottom-right",
              autoClose: 1000,
            });

            // // eslint-disable-next-line no-console
            // console.log(response.data);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      }
    },

    fetchQuizDetails(random_string) {
      CampaignService.getAQuiz(random_string)
        .then((response) => {
          this.quizDetails = response.data.quiz;
          this.questions.questionsData = response.data.questions;

          // // eslint-disable-next-line no-console
          // console.log(response.data)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    selectCorrectAnswer(ans) {
      //get the question object to modify
      var question = this.questions.questionsData.find(
        (quetObj) => quetObj.id === ans.question_id
      );

      //foreach answer and change is_correct to 0
      question.answers.forEach((answer) => {
        answer.is_correct = 0;
      });

      //then set the passed in answer correct answer to 1
      ans.is_correct = 1;

      // // eslint-disable-next-line no-console
      // console.log(question)
    },

    updateQuiz() {
      CampaignService.updateQuestionsAndAnswers(
        this.quizDetails.random_string,
        this.questions
      )
        .then((response) => {
          toast("Quiz updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // // eslint-disable-next-line no-console
          // console.log(response.data)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });

      // // eslint-disable-next-line no-console
      // console.log(this.questions)
    },

    exitWizard() {
      if (
        confirm(
          "You're exiting the wizard without publishing or saving this talk as draft. Changes will not be saved!"
        )
      ) {
        this.router.push({
          name: "ViewACampaign",
          params: { random_string: this.currentCampaign.random_string },
        });

        this.resetWizard();

        console.log("exit editor");
      }
    },

    fetchAcknowledgements(schedule_message_id) {
      this.allAcknowledgments = [];
      this.scheduledMessage = {};

      CampaignService.getAcknowledgementStatus(schedule_message_id)
        .then((response) => {
          this.scheduledMessage = response.data.scheduledMessage;
          this.allAcknowledgments = response.data.acknowledgements;

          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    exportDataAsExcel(schedule_message_id) {
      CampaignService.exportAckData(schedule_message_id)
        .then((response) => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          // Open the URL on new Window
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", "AcknowledgementsData.xlsx");
          document.body.appendChild(link);
          link.click();

          // console.log(response.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchAllEmployees() {
      TeamService.getAllEmployees()
        .then((response) => {
          this.employees = response.data.employees;

          // // eslint-disable-next-line no-console
          // console.log(response.data);

        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    fetchEmployeeTalks(uuid) {
      TeamService.showTalksForThisEmployee(uuid)
        .then((response) => {
          this.employee = response.data.employee;
          this.team = response.data.team;
          this.acknowledgements = response.data.acknowledgements;

          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    openPreviewTalkForAEmployee() {
      this.showPreviewTalkForAEmployee = true;
    },

    closePreviewTalkForAEmployee() {
      this.showPreviewTalkForAEmployee = false;
    },

    setCurrentPost(post) {
      this.currentPost = post;

      if (this.currentPost.message_goal_id == 3) {
        //write a function to return if video_embed_code includes 'youtube'
        if (this.isThisVideoYoutube()) {
          this.thisVideoIsYoutube = true;
        }
      }

      if (this.currentPost.message_goal_id == 2) {
        CampaignService.checkIfPDFExistInLibraryForThisPost(
          this.currentPost.random_string
        )
          .then((response) => {
            this.doesPDFNameExistInLibrary = response.data.doesExistInLibrary;

            this.buildPDFurl3();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    isThisVideoYoutube() {
      // Check if videoCode includes 'youtube' and return true or false
      return this.currentPost.video_embed_code.includes("youtube");
    },

    buildPDFurl3() {
      let url = "";

      if (this.doesPDFNameExistInLibrary === 1) {
        url =
          "https://safelyio.nyc3.cdn.digitaloceanspaces.com/pdftalks/" +
          this.currentPost.pdf_url_path;

        this.source1 = url;

        return;
      }

      if (this.doesPDFNameExistInLibrary === 0) {
        url =
          "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" +
          this.currentPost.manager_id +
          "/9j1ks/" +
          this.currentPost.pdf_url_path;

        this.source1 = url;

        return;
      }
    },

    //the employee uuid
    exportEmployeeTaklsDataAsExcel(uuid) {
      CampaignService.exportAllTalksForAEmployee(uuid)
        .then((response) => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          // Open the URL on new Window
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute(
            "download",
            this.employee.fname + this.employee.lname + "SafetyTalks.xlsx"
          );
          document.body.appendChild(link);
          link.click();

          // console.log(response.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openGenQuizWithAIModal() {
      this.showGenQuizWithAIModal = true;
    },

    closeGenQuizWithAIModal() {
      this.showGenQuizWithAIModal = false;
    },

    openAIQuizResultsModal() {
      this.showAIQuizResultsModal = true;
    },

    closeAIQuizResultsModal() {
      this.showAIQuizResultsModal = false;
    },

    generateQuizText() {
      this.isLoading = true;

      if (this.quizTextCreatorFormData.amt_of_questions > 7) {
        alert("Maximum of 7 quwstions is allowed");

        this.isLoading = false;

        return;
      }

      if (this.quizTextCreatorFormData.amt_of_answers_per_question > 5) {
        alert("Maximum of 5 answers is allowed");

        this.isLoading = false;

        return;
      }

      this.quizTextCreatorFormData.talk_content = this.post.postData.content;

      // console.log(this.quizTextCreatorFormData, this.post.postData.content)

      CampaignService.aiGenerateQuizText(this.quizTextCreatorFormData)
        .then((response) => {
          this.questionsCreatedByAIArray = response.data.contentArray.questions;

          this.openAIQuizResultsModal();

          // console.log(this.questionsCreatedByAIArray)

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateQuizFromPDF() {
      this.isLoading = true;

      if (this.quizPDFCreatorFormData.amt_of_questions > 7) {
        alert("Maximum of 7 quwstions is allowed");

        this.isLoading = false;

        return;
      }

      if (this.quizPDFCreatorFormData.amt_of_answers_per_question > 5) {
        alert("Maximum of 5 answers is allowed");

        this.isLoading = false;

        return;
      }

      this.quizPDFCreatorFormData.pdf_content = this.pdf;

      // console.log(this.quizPDFCreatorFormData)

      CampaignService.aiGenerateQuizPdf(this.quizPDFCreatorFormData)
        .then((response) => {
          this.questionsCreatedByAIArray = response.data.contentArray.questions;

          this.openAIQuizResultsModal();

          // console.log(this.questionsCreatedByAIArray)

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    useThisQuizResults() {
      this.questionsCreatedByAIArray.forEach((question) => {
        // Create a new object for each question
        let newQuestionObject = {
          question: "",
          correctAnswerIndex: null,
          answersData: [],
        };

        // Assign the question
        newQuestionObject.question = question.question_text;

        let correctIndex = null;

        // Assign answers
        question.answers.forEach((ans, index) => {
          let newAnswer = {
            answer: ans.answer_text,
          };

          newQuestionObject.answersData.push(newAnswer);

          if (ans.is_correct) {
            correctIndex = index;
          }
        });

        newQuestionObject.correctAnswerIndex = correctIndex;

        // Push the newly created question object into the questionsData array
        this.questions.questionsData.push(newQuestionObject);
      });

      this.closeAIQuizResultsModal();
      this.closeGenQuizWithAIModal();
    },

    openShowEditQuestiosModal() {
      this.showEditQuestiosModal = true;
    },

    closeShowEditQuestiosModal() {
      this.showEditQuestiosModal = false;
    },

    setCorrectAnswerIndexInQuestionsData(questionIndex, ansIndex) {
      this.questions.questionsData[questionIndex].correctAnswerIndex = ansIndex;

      // console.log('question  index:' + questionIndex, 'answer  index:' + ansIndex)
    },

    deleteAnswerInQuestionsArray(questionIndex, ansIndex) {
      // Check if the question exists at the given index
      if (this.questions.questionsData[questionIndex]) {
        // Check if the number of answers is greater than 2
        if (
          this.questions.questionsData[questionIndex].answersData.length > 2
        ) {
          // Remove the answer at the specified index
          this.questions.questionsData[questionIndex].answersData.splice(
            ansIndex,
            1
          );
        } else {
          // Alert if there are not enough answers to remove one
          alert("A question must have a minimum of 2 answers.");
        }
      }
    },

    openResendTalkEmailToEmployeeModal() {
      this.showResendTalkEmailToEmployeeModal = true;
    },

    closeResendTalkEmailToEmployeeModal() {
      this.showResendTalkEmailToEmployeeModal = false;
    },

    setCurrrentEmployeeToResendEmail(ack) {
      this.currentEmployee = ack.user;
      this.currentAck = ack;

      // console.log(ack)
    },

    resendTalkToThisEmployee() {
      this.isLoading = true;

      let formData = {
        ack: this.currentAck,
      };

      CampaignService.resendSafetyTalkEmailToEmployee(formData)
        .then((response) => {
          // console.log(response.data)

          this.isLoading = false;

          this.closeResendTalkEmailToEmployeeModal();

          toast("Talk resent!", {
            position: "bottom-right",
            autoClose: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportAllTalksWithEmployeesUnderACampaign(random_string) { 

      this.isExporting = true

      CampaignService.exportAllTalksWithEmployeesForACampaign(random_string)
        .then((response) => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          // Open the URL on new Window
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute(
            "download",
            this.currentCampaign.name + "TalksDataWithEmployees.xlsx"
          );
          document.body.appendChild(link);
          link.click();


          this.isExporting = false

          // console.log(response.data)
        })
        .catch((err) => {

          this.isExporting = false

          console.log(err);
        });
    },

    exportAllTalksOnlyUnderACampaign(random_string) { 

      this.isExporting = true

      CampaignService.exportAllTalksDataOnlyForACampaign(random_string)
        .then((response) => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          // Open the URL on new Window
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute(
            "download",
            this.currentCampaign.name + "TalksData.xlsx"
          );
          document.body.appendChild(link);
          link.click();

          this.isExporting = false

          // console.log(response.data)
        })
        .catch((err) => {

          this.isExporting = false
          
          console.log(err);
        });
    },

    openShowViewUserTeamsModal() {
      this.showViewUserTeamsModal = true;
    },

    closeShowViewUserTeamsModal() {
      this.showViewUserTeamsModal = false;
    },

    setCurrentUserTeams(userTeams) {
      this.currentUserTeams = []; //reset

      userTeams.forEach((userTeam) => {
        if (userTeam.team) {
          this.currentUserTeams.push(userTeam.team);
        }
      });
    },

    openMassResendModal() {
      this.showMassResendModal = true;
    },

    closeMassResendModal() {
      this.showMassResendModal = false;
    },

    filterAckList() {
      return this.allAcknowledgments.filter((ack) => ack.is_acknowledged === 0);
    },

    massResendToThisWhoDidNotAck() {
      if (
        confirm(
          "Are you sure want to mass resend this talk? Those who did not acknowledge will receive this notification."
        )
      ) {

        const formData = {
          the_list: this.filterAckList(),
        };

        CampaignService.massResendSafetyTalkEmailToEmployeesWhoDidNotAck(formData)
          .then((response) => {

            // console.log(response.data)
            
            this.closeMassResendModal()

            toast("Safety talk resent!", {
              position: "bottom-right",
              autoClose: 2000,
            });


            
          }).catch((err) => {

            console.log(err)
            
          });

        console.log(formData);
      }


    },


    openExportCampaignOptionsModal(){
      this.showCampaignExportOptionsModal = true
    },


    closeExportCampaignOptionsModal(){
      this.showCampaignExportOptionsModal = false
    },


    openQuizAttemptModal(user){

      this.currentQuizAttemptEmployee = user
      this.fetchQuizAttempts()

      this.showQuizAttemptModal = true
    },

    closeQuizAttemptModal(){
      this.showQuizAttemptModal = false
    },

 


    fetchQuizAttempts(){

      this.isLoadingQuizAttempts = true

      const formData = {
        employee_id: this.currentQuizAttemptEmployee.id,
        schedule_message_id: this.scheduledMessage.id
      }

      CampaignService.getAllQuizAttempts(formData)
        .then((response) => {

          this.quizAttempts = response.data.quizAttempts

          this.isLoadingQuizAttempts = false 

          // console.log(response.data)
          
        }).catch((err) => {

          this.isLoadingQuizAttempts = false

          console.log(err)
          
        });


      // console.log(formData)

    },

    toggleQuizAttempt(quizAttempt){

      quizAttempt.show_inner_box = quizAttempt.show_inner_box === 1 ? 0 : 1;

    },

    toggleQuizAnswers(quizResult){

      quizResult.show_ans_options = quizResult.show_ans_options === 1 ? 0 : 1;

    }




  },




});
