import apiClient from "./MyClient";

export default {
  getAllCampaigns() {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/campaigns/");
  },

  getCampaignDetails(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/campaign/edit/" + randomString);
  },

  updateCampaignDetails(randomString, data) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.put("/api/campaign/update/" + randomString, data);
  },

  storeCampaign(campaignObject) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/campaign/store", campaignObject);
  },

  deleteThisCampaign(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.delete("/api/campaign/delete/" + randomString);
  },

  showCampaign(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/campaign/" + randomString);
  },

  storeNewMessage(randomString, motherArrayData) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post(
      "/api/campaign/message/store/" + randomString,
      motherArrayData
    );
  },

  getScheduledMessage(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/campaign/message/" + randomString);
  },

  updateScheduledMessage(randomString, updatedMessageData) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.put(
      "/api/campaign/message/update/" + randomString,
      updatedMessageData
    );
  },

  getPostContent(randomString) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/campaign/post/" + randomString);
  },

  updatePost(randomString, updatedPostData) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.put(
      "/api/campaign/post/update/" + randomString,
      updatedPostData
    );
  },

  //this only return text-based talks
  getAllSafetyTalksFromLibrary() {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/safetytalks");
  },

  //this only return video-based talks
  getAllVideoTalksFromLibrary() {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/videotalks");
  },

  updateStatus(randomString, statusValue) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.put("/api/update/status/" + randomString, statusValue);
  },

  getAcknowledgementStatus(scheduledMessageId) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/employees/acknowlegment/" + scheduledMessageId);
  },

  exportAckData(scheduledMessageId) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/export/acknowlegment/" + scheduledMessageId, { responseType: 'blob' });

  },

  exportAllEmployeesOnATeam(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/export/team/employees/" + randomString);
  },

  uploadThisPdf(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/upload/pdf", formData, config);
  },

  deleteFileFromDOSpaces(formData) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/delete/pdf", formData, config);
  },

  updatePDF(formData) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/update/pdf", formData, config);
  },

  uploadTextEditorImage(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/upload/texteditor/image", formData, config);

  },

  updateVideoPost(randomString, updateVideoPost) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/update/video/" + randomString, updateVideoPost);
  },

  exportAllTalksForAEmployee(uuid) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/export/employee/talks/" + uuid, { responseType: 'blob' });
  },

  deleteSceduledMessage(id) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/campaign/message/delete/" + id);
  },

  //EXPORT TALKS ONLY
  exportAllTalksDataOnlyForACampaign(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/export/campaign/alltalks/" + randomString, { responseType: 'blob' });
  },


  //EXPORT TALKS WITH EMPLOYEES
  exportAllTalksWithEmployeesForACampaign(randomString) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/export/campaign/everytalk/" + randomString, { responseType: 'blob' });

  }, 

  getCampaignReport(randomString) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/campaign/report/" + randomString);

  },


  generateTextTalkWithAI(formData) {


    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/ai/generate/talk/", formData);

  },

  getAQuiz(randomString) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.get('/api/quiz/' + randomString);
  },


  updateQuestionsAndAnswers(randomString, data) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.post('/api/update/quiz/data/' + randomString, data);

  },

  checkIfPDFExistInLibraryForThisPost(randomString){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.get('/api/post/pdf/librarycheck/' + randomString); 


  },


  aiGenerateQuizText(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/ai/generate/quiz/text", formData);
  },

  aiGenerateQuizPdf(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/ai/generate/quiz/pdf", formData, config);
  },

  resendSafetyTalkEmailToEmployee(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/resend/employee/talk", formData);
  },

  massResendSafetyTalkEmailToEmployeesWhoDidNotAck(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/mass/resend/to/employees/didnt-ack", formData);

  },



  downloadTextTalkAsPDF(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/text/pdf/download", formData, { responseType: 'blob' });

  }, 


  getAllQuizAttempts(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/all/quiz/attempts", formData);

  },




};
