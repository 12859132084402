<template>
    <div class="theSideBar">



        <div class="logoWrapper">

            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />

        </div>



        <div class="mainMenuWrapper">

            
            <!-- {{ authStore.toolbox_talk_app }}  -->


            <router-link class="linkStyle" :to="{ name: 'AppsHome' }">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <i class="bi bi-grid"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Apps</span>

                    </div>

                </div>

            </router-link>





            <router-link class="linkStyle" :to="{ name: 'ToolboxHome' }" 
            v-if="authStore.toolbox_talk_app.features_access_type === 'standard_talks' || 
            authStore.toolbox_talk_app.features_access_type === 'both' 
            "
            >

                <div class="menuItemRow" :class="{ menuItemRowSelected: isToolboxHomeActive }">


                    <div class="iconArea">

                        <i class="bi bi-chat-right-dots"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Campaigns</span>


                    </div>


                </div>



            </router-link>



            <router-link class="linkStyle" :to="{ name: 'MainReport' }"
            v-if="authStore.toolbox_talk_app.features_access_type === 'standard_talks' ||
             authStore.toolbox_talk_app.features_access_type === 'both' 
            "
            >

                <div class="menuItemRow" :class="{ menuItemRowSelected: isMainReports }">


                    <div class="iconArea">

                        <i class="bi bi-clipboard-data"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Reports</span>

                    </div>

                </div>


            </router-link>




            <router-link class="linkStyle" :to="{ name: 'ViewAllTeams' }">

                <div class="menuItemRow" :class="{ menuItemRowSelected: isViewAllTeamsActive }">


                    <div class="iconArea">

                        <i class="bi bi-people"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Teams</span>

                    </div>

                </div>

            </router-link>



            <router-link class="linkStyle" :to="{ name: 'ViewAllEmployees' }">

                <div class="menuItemRow" :class="{ menuItemRowSelected: isViewAllEmployeesActive }">


                    <div class="iconArea">




                        <i class="bi bi-person-check"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Employees</span>

                    </div>

                </div>

            </router-link>



            <router-link class="linkStyle" :to="{ name: 'ViewAllLibraryTalks' }">

                <div class="menuItemRow" :class="{ menuItemRowSelected: isViewAllLibraryTalksActive }">


                    <div class="iconArea">

                        <i class="bi bi-journal-text"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Toolbox Talk Library</span>

                    </div>

                </div>


            </router-link>




            <router-link class="linkStyle" :to="{ name: 'ManagerViewAllGroupTalks' }"
            v-if="authStore.toolbox_talk_app.features_access_type === 'group_talks' ||
             authStore.toolbox_talk_app.features_access_type === 'both' 
            "
            >

                <div class="menuItemRow" :class="{ menuItemRowSelected: isManagerViewAllGroupTalks }">


                    <div class="iconArea">

                        <i class="bi bi-broadcast"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Group Talks</span>

                    </div>

                </div>


            </router-link>

          

            <div v-if="authStore.currentAppAccess">

                <div v-if="authStore.currentAppAccess.is_allowed_subadmin_feature === 'yes'">

                    <div v-if="authStore.currentUser">

                        <router-link v-if="authStore.currentUser.role_id == 2" class="linkStyle"
                            :to="{ name: 'ViewAllSubManagers' }">

                            <div class="menuItemRow" :class="{ menuItemRowSelected: isViewAllSubManagers }">


                                <div class="iconArea">

                                    <i class="bi bi-person-vcard" style="font-size: 14px;"></i>

                                </div>

                                <div class="labelArea">

                                    <span style="float: left; margin-left: 0px;">Sub Managers</span>

                                </div>

                            </div>


                        </router-link>

                    </div>

                </div>



            </div>




            <div v-if="authStore.currentUser">

                <router-link v-if="authStore.currentUser.role_id == 2 || authStore.currentUser.role_id == 4"
                    class="linkStyle" :to="{ name: 'UserAccount' }">

                    <div class="menuItemRow" :class="{ menuItemRowSelected: isUserAccount }">


                        <div class="iconArea">

                            <i class="bi bi-person-bounding-box" style="font-size: 14px;"></i>

                        </div>

                        <div class="labelArea">

                            <span style="float: left; margin-left: 0px;">Account</span>

                        </div>

                    </div>


                </router-link>

            </div>



            <div class="menuItemRow" @click="authStore.logoutUser">


                <div class="iconArea">

                    <i class="bi bi-box-arrow-right"></i>

                </div>

                <div class="labelArea">

                    <span style="float: left; margin-left: 0px;">Logout</span>

                </div>

            </div>





        </div>





    </div>
</template>

<script setup>

import { useAuthStore } from "@/stores/AuthStore";
import { computed, onMounted } from "vue";
import { useRoute } from 'vue-router';


const authStore = useAuthStore();
const route = useRoute();

const isToolboxHomeActive = computed(() => route.name === 'ToolboxHome');
const isViewAllTeamsActive = computed(() => route.name === 'ViewAllTeams');
const isViewAllLibraryTalksActive = computed(() => route.name === 'ViewAllLibraryTalks');
const isViewAllEmployeesActive = computed(() => route.name === 'ViewAllEmployees');
const isManagerViewAllGroupTalks = computed(() => route.name === 'ManagerViewAllGroupTalks');
const isViewAllSubManagers = computed(() => route.name === 'ViewAllSubManagers');
const isMainReports = computed(() => route.name === 'MainReport');
const isUserAccount = computed(() => route.name === 'UserAccount');




onMounted(() => {


    authStore.getAManagerAppAccessInfo()


})


</script>

<style scoped>
.theSideBar {
    background-color: #f9f9f9;
    width: 17%;
    overflow-y: auto;

}

.logoWrapper {
    display: flex;
    /* Enable Flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    width: 100%;
    height: 50px;
    padding-top: 20px;

    /* background-color: aqua; */
}

.imgLogo {
    width: 110px;
}


.mainMenuWrapper {
    /* background-color: aqua; */
    width: 100%;
    margin-top: 20px;
    /* padding: 20px; */
}

.menuItemRow {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;

}


.iconArea {
    /* background-color: #b8ffd3; */
    width: 10%;
    display: flex;
    align-items: center;
}

.labelArea {

    /* background-color: #36e1e1; */
    width: 80%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}


.menuItemRow:hover {
    background-color: rgb(232, 243, 234);
}

.menuItemRowSelected {
    background-color: rgb(232, 243, 234);
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}
</style>