<template>
    <div>
        <div class="mainWrapper">
            <SuperAdminSideBar />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">

                        <div v-if="superAdminStore.manager">
                            {{ superAdminStore.manager.company_name }} - Managed by {{ superAdminStore.manager.name }}
                        </div>


                        <div>

                            <button class="button-6" role="button" @click="superAdminStore.openBulkUploadEmployeesModal"
                                style="margin-right: 15px;">
                                Bulk Upload Employees
                            </button>

                            <button class="button-6" role="button" @click="superAdminStore.openManageAppAccessModal">
                                Add App Access
                            </button>

                        </div>



                    </div>


                    <div class="innerContainer">

                        <div class="visualBoxesWrapper">


                            <div class="dashBox">

                                <div class="numberHeader" v-if="superAdminStore.employees">
                                    {{ superAdminStore.employees.length }}
                                </div>

                                <div class="labelbottom">
                                    Employees
                                </div>

                            </div>

                            <div class="dashBox">

                                <div class="numberHeader" v-if="superAdminStore.subManagers">
                                    {{ superAdminStore.subManagers.length }}
                                </div>

                                <div class="labelbottom">
                                    Sub Managers
                                </div>

                            </div>

                            <!-- <div class="dashBox">

                                <div class="numberHeader">
                                    Apps
                                </div>

                                <div class="labelbottom">
                                    View This Manager Apps Access
                                </div>

                            </div> -->

                            <div class="dashBox">

                                <div class="numberHeader" v-if="superAdminStore.manager">
                                    {{ superAdminStore.formatDate(superAdminStore.manager.created_at) }}
                                </div>

                                <div class="labelbottom">
                                    Account Creation Date
                                </div>

                            </div>







                        </div>



                        <div class="tabsWrapper">


                            <div class="tabItem" v-for="(tab, index) in superAdminStore.tabs" :key="index"
                                :class="{ tabItemSelected: tab.type === superAdminStore.currentTab }"
                                @click="superAdminStore.currentTab = tab.type">

                                <b>{{ tab.name }}</b>

                            </div>


                        </div>



                        <div v-if="superAdminStore.currentTab === 'apps_list'" class="tableWrapper">

                            <table class="table table-hover my-custom-table">
                                <thead>
                                    <tr>
                                        <th scope="col">App Name</th>
                                        <th scope="col">Has Access</th>
                                        <th scope="col">Employee Count Limit</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Sub Manager Enabled</th>
                                        <th scope="col">SubManager Limit</th>
                                        <th scope="col"></th>

                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(appAcess, index) in superAdminStore.appAccessList" :key="index">


                                        <td>

                                            <div v-if="appAcess.app">
                                                {{ appAcess.app.name }}
                                            </div>

                                        </td>

                                        <td>

                                            <span v-if="appAcess.has_access === 'yes'" class="badge bg-success">{{
                            appAcess.has_access }}</span>
                                            <span v-if="appAcess.has_access === 'no'" class="badge bg-danger">{{
                            appAcess.has_access }}</span>


                                        </td>

                                        <td>
                                            {{ appAcess.team_size_limit }}
                                        </td>

                                        <td>
                                            {{ superAdminStore.formatDate(appAcess.start_date) }}
                                        </td>

                                        <td>
                                            {{ superAdminStore.formatDate(appAcess.end_date) }}
                                        </td>

                                        <td>

                                            <span v-if="appAcess.is_allowed_subadmin_feature === 'yes'"
                                                class="badge bg-success">yes</span>
                                            <span v-if="appAcess.is_allowed_subadmin_feature === 'no'"
                                                class="badge bg-danger">no</span>

                                        </td>

                                        <td>

                                            <div v-if="appAcess.sub_admin_limit == null">
                                                0
                                            </div>

                                            <div v-else>
                                                {{ appAcess.sub_admin_limit }}
                                            </div>

                                        </td>

                                        <td>

                                            <i @click="() => { superAdminStore.setCurrentAppAccess(appAcess); superAdminStore.openShowEditAppAccessModal(); }"
                                                class="bi bi-pencil"></i>

                                        </td>




                                    </tr>
                                </tbody>




                            </table>







                        </div>

                        <div v-if="superAdminStore.currentTab === 'employees'" class="tableWrapper">

                            <table class="table table-hover my-custom-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Date Joined</th>

                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(employee, index) in superAdminStore.employees" :key="index">


                                        <td>
                                            {{ employee.name }}
                                        </td>

                                        <td>

                                            <div v-if="employee.role_id === 3">
                                                <span class="badge bg-dark">Regular employee</span>

                                            </div>

                                            <div v-if="employee.role_id === 4">

                                                <span class="badge bg-success">Sub Manager</span>
                                            </div>

                                            <div v-if="employee.role_id === 5">
                                                <span class="badge bg-success">Master Account Admin</span>

                                            </div>


                                        </td>

                                        <td>
                                            {{ superAdminStore.formatDate(employee.created_at) }}
                                        </td>




                                    </tr>
                                </tbody>




                            </table>







                        </div>






                    </div>







                </div>
            </div>
        </div>




        <div v-if="superAdminStore.showManageAccessModal" class="modal-overlay">

            <div class="modal-box" style="width: 800px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Manage Apps Access</b>
                    </div>

                    <div>
                        <i @click="superAdminStore.closeManageAppAccessModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">





                    <form @submit.prevent="superAdminStore.addManagerAccessForApp">






                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Select Safelyio App</b></label>

                            <select v-model="superAdminStore.newManagerAppAccessFormData.safelyio_app_id"
                                class="form-select custom-select" required>
                                <option value="" disabled>Choose a app</option>

                                <option v-for="(app, index) in superAdminStore.apps" :key="index" :value="app.id">
                                    {{ app.name }}
                                </option>

                            </select>


                        </div>



                        <div class="mb-3" v-if="superAdminStore.newManagerAppAccessFormData.safelyio_app_id === 1">

                            <label class="form-label" style="float: left"><b>Select Feature Access</b></label>

                            <select v-model="superAdminStore.newManagerAppAccessFormData.features_access_type"
                                class="form-select custom-select" required>
                                <option value="" disabled>Choose access</option>

                                <option v-for="(feature, index) in superAdminStore.toolboxTalkFeatureAccessOptions" :key="index" :value="feature.type">
                                    {{ feature.name }}
                                </option>

                            </select>



                        </div>




                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Start Date</b></label>
                            <input type="date" v-model="superAdminStore.newManagerAppAccessFormData.start_date"
                                class="form-control inputStyle" required />
                        </div>


                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>End Date</b></label>
                            <input type="date" v-model="superAdminStore.newManagerAppAccessFormData.end_date"
                                class="form-control inputStyle" required />
                        </div>


                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Employees Limit</b></label>
                            <input type="number" v-model="superAdminStore.newManagerAppAccessFormData.team_size_limit"
                                class="form-control inputStyle" required />
                        </div>

                        <div class="mb-3">


                            <div style="display: flex;">

                                <label class="form-label" style="float: left; margin-right: 10px;"><b>Allow Sub Manager
                                        Feature
                                    </b></label>

                                <div class="form-check form-switch">

                                    <input
                                        v-if="superAdminStore.newManagerAppAccessFormData.is_allowed_subadmin_feature === 'yes'"
                                        @click="superAdminStore.newManagerAppAccessFormData.is_allowed_subadmin_feature = 'no'"
                                        class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>

                                    <div v-else class="form-check form-switch">

                                        <input
                                            @click="superAdminStore.newManagerAppAccessFormData.is_allowed_subadmin_feature = 'yes'"
                                            class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                    </div>

                                </div>

                                <div style="display: flex;"
                                    v-if="superAdminStore.newManagerAppAccessFormData.is_allowed_subadmin_feature === 'yes'">

                                    <label class="form-label" style="float: left; margin-right: 10px;"><b>Sub Managder
                                            Limit
                                        </b></label>

                                    <input type="number" style="width: 100px;"
                                        v-model="superAdminStore.newManagerAppAccessFormData.sub_admin_limit"
                                        class="form-control" required />

                                </div>


                            </div>








                        </div>



                        <div>
                            <button type="submit" class="btn btn-dark createBTN">
                                <b>Add</b>
                            </button>
                        </div>
                    </form>


                    <!-- 
                    <table class="table">
                        <thead>
                            <tr style="text-align: left;">
                       
                                <th scope="col">App Name</th>
                                <th scope="col">Access</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="text-align: left;">
                                <td>Toolbox Talk App</td>

                                <td>
                                    <span class="badge rounded-pill bg-success">Yes</span>
                                </td>

                                <td>
                                    
                                    <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="flexSwitchCheckDefault" checked>
                                                </div>
                                    
                                </td>
                            </tr>
                     
                        </tbody>
                    </table>
 -->


                </div>
            </div>
        </div>

        <div v-if="superAdminStore.showEditAppAccessModal" class="modal-overlay">

            <div class="modal-box" style="width: 800px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Edit Apps Access</b>
                    </div>

                    <div>
                        <i @click="superAdminStore.closeShowEditAppAccessModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>


                <!-- {{ superAdminStore.currentAppAccess }} -->

                <div class="modal-content">



                    <form @submit.prevent="superAdminStore.saveAppAccessUpdate">


                        <div class="mb-3" v-if="superAdminStore.currentAppAccess.app">



                            <span class="badge bg-success" style="font-size: 20px;"> {{
                            superAdminStore.currentAppAccess.app.name }}</span>




                        </div>

                        <div class="mb-3" v-if="superAdminStore.currentAppAccess.app.id === 1">
                            

                            <label class="form-label" style="float: left"><b>Select Feature Access</b></label>

                            <select v-model="superAdminStore.currentAppAccess.features_access_type"
                                class="form-select custom-select" required>
                                <option value="" disabled>Choose access</option>

                                <option v-for="(feature, index) in superAdminStore.toolboxTalkFeatureAccessOptions" :key="index" :value="feature.type">
                                    {{ feature.name }}
                                </option>

                            </select>



                        </div>

                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Start Date</b></label>
                            <input type="date" v-model="superAdminStore.currentAppAccess.start_date"
                                class="form-control inputStyle" required />
                        </div>


                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>End Date</b></label>
                            <input type="date" v-model="superAdminStore.currentAppAccess.end_date"
                                class="form-control inputStyle" required />
                        </div>


                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Employees Limit</b></label>
                            <input type="number" v-model="superAdminStore.currentAppAccess.team_size_limit"
                                class="form-control inputStyle" required />
                        </div>

                        <div class="mb-3">


                            <div style="display: flex;">

                                <label class="form-label" style="float: left; margin-right: 10px;"><b>Allow Sub Manager
                                        Feature
                                    </b></label>

                                <div class="form-check form-switch">

                                    <input v-if="superAdminStore.currentAppAccess.is_allowed_subadmin_feature === 'yes'"
                                        @click="superAdminStore.toggleNOSubAdminForEditModal" class="form-check-input"
                                        type="checkbox" id="flexSwitchCheckDefault" checked>

                                    <div v-else class="form-check form-switch">

                                        <input
                                            @click="superAdminStore.currentAppAccess.is_allowed_subadmin_feature = 'yes'"
                                            class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                    </div>

                                </div>

                                <div style="display: flex;"
                                    v-if="superAdminStore.currentAppAccess.is_allowed_subadmin_feature === 'yes'">

                                    <label class="form-label" style="float: left; margin-right: 10px;"><b>Sub Managder
                                            Limit
                                        </b></label>

                                    <input type="number" style="width: 100px;"
                                        v-model="superAdminStore.currentAppAccess.sub_admin_limit" class="form-control"
                                        required />

                                </div>





                            </div>



                            <div class="mb-3" style="margin-top: 20px;">
                                <label class="form-label" style="float: left; margin-right: 50px;"><b>Disable app (user
                                        no longer has access)
                                    </b></label>

                                <div class="form-check form-switch">

                                    <input v-if="superAdminStore.currentAppAccess.has_access === 'yes'"
                                        @click="superAdminStore.currentAppAccess.has_access = 'no'"
                                        class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">

                                    <div v-else class="form-check form-switch">

                                        <input @click="superAdminStore.currentAppAccess.has_access = 'yes'"
                                            class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                            checked>
                                    </div>

                                </div>
                            </div>






                        </div>



                        <div>
                            <button type="submit" class="btn btn-dark createBTN">
                                <b>Update</b>
                            </button>
                        </div>
                    </form>



                    <button @click="superAdminStore.deleteThisAppAccess" class="btn btn-danger" style="width: 100px; margin-top: 70px; font-size: 12px;" >Delete App Access</button>


                </div>
            </div>
        </div>


        <div v-if="superAdminStore.showBulkUploadEmployeesModal" class="modal-overlay">

            <div class="modal-box" style="width: 500px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Bulk Upload Employees</b>
                    </div>

                    <div>
                        <i @click="superAdminStore.closeBulkUploadEmployeesModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


                    <form @submit.prevent="superAdminStore.uploadEmployeesInBulk" yle="text-align: left;">

                        <div class="mb-3">


                            <label class="form-label"><b>Select A Team</b></label>


                            <select v-model="superAdminStore.newBulkUpload.team_id" class="form-select"
                                aria-label="Default select example">

                                <option v-for="(team, index) in superAdminStore.teams" :key="index" :value="team.id">
                                    {{ team.name }}
                                </option>

                            </select>



                        </div>

                        <div class="mb-3">

                            <div class="assetFilesInputBox" @click="superAdminStore.triggerInput">

                                Upload Excel File

                            </div>


                            <div v-if="superAdminStore.newBulkUpload.file !== null" style="margin-top: 20px;">

                                <b style="font-size: 16px;">{{ superAdminStore.newBulkUpload.file.name }}</b>

                            </div>



                        </div>


                        <div class="mb-3">

                            <button v-if="superAdminStore.isLoading" class="btn btn-dark" disabled style="width: 100%;">Uploading...</button>

                            <button v-else type="submit" class="btn btn-dark" style="width: 100%;">Upload and Create Accounts
                                Automatically</button>

                        </div>




                    </form>




                </div>
            </div>
        </div>



    </div>
</template>

<script setup>
/* eslint-disable */
import SuperAdminSideBar from "@/components/SuperAdminSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, defineProps } from 'vue';
import { useSuperAdminStore } from "@/stores/SuperAdminStore";


const superAdminStore = useSuperAdminStore();


const props = defineProps({
    uuid: String
})



onMounted(() => {

    superAdminStore.fetchAManager(props.uuid)


})






</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}


.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
    margin-top: 20px;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100px;
}

.button-13:hover {
    background-color: #f7fafa;
}

.button-13:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}


.thePreviewContent {
    height: 600px;
    overflow-y: auto;

}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}



.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.visualBoxesWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    /* background-color: #d5d9d9; */
    height: 100px;
    margin-bottom: 40px;
    margin-top: 15px;
}


.dashBox {

    /* background: linear-gradient(90deg,#ddefbb,#fee); */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    /* margin: 5px; */
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;

}

.numberHeader {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    /* background-color: #008296; */
    width: 100%;
}

.labelbottom {
    text-align: left;
    /* background-color: rgb(227, 179, 179); */
    width: 100%;
}


.tabsWrapper {
    display: flex;
    width: 50%;
    background-color: #a9baba;
}


.tabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    height: 50px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
}

.tabItem:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    height: 50px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    height: 50px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
}


.assetFilesInputBox {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 13px;
    border-radius: 10px;
    border: 2px dotted rgb(66, 65, 65);
    cursor: pointer;
}
</style>